import {Settings} from 'luxon';
import {version} from '../../../package.json';

/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap')

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: '#app',

    data: {
        locale: 'en',
        productUrl: null,
        productVersion: version,
        requestId: null,
        clientToken: '',
        selection: '1month',
        instance: '',
        eventAt: '',
        timerId: 0,
        countup: false,
    },

    mixins: [(typeof mixin === 'undefined' ? '' : mixin)],

    mounted: function () {
        this.productUrl = $('input[name=productUrl]').val()
        this.countup = $("#app").data("flag");
        Lang.setFallback('en');
        Settings.defaultLocale = this.getLang()
        $('.date-format').each(function () {
            let dt = DateTime.fromISO($(this).text())
            $(this).text(dt.toLocaleString(DateTime.DATE_MED))
        })
        $('.datetime-format').each(function () {
            let dt = DateTime.fromISO($(this).text())
            $(this).text(dt.toLocaleString(DateTime.DATETIME_MED))
        })
        $('[data-toggle="popover"]').tooltip({container: 'table'});

        if ($('#countdown').length) {
            this.eventAt = new Date($('#event_at').val())
            this.startTimer()
        }
        if (this.fullScreenAvailable && false) { //@TODO: fix fullscreen
            $('.fullscreen').show().on('click', (e) => {
                const requestFullScreen =
                    document.body.requestFullscreen ||
                    document.body.mozRequestFullScreen ||
                    document.body.webkitRequestFullScreen ||
                    document.body.msRequestFullscreen;
                requestFullScreen.call(document.body)
            })
        }
    },

    computed: {

        isFullScreen: function () {
            return document.body.fullscreenElement === null
        },

        fullScreenAvailable: function () {
            return document.fullscreenEnabled ||
                document.webkitFullscreenEnabled ||
                document.mozFullScreenEnabled ||
                document.msFullscreenEnabled
        },
    },

    methods: {

        confirm: function (e, j, c) {
            let link = e.currentTarget
            if (typeof $(link).data('confirmed') === 'undefined') {
                e.preventDefault()
                Swal.fire({
                    title: Lang.get('messages.AreYouSure'),
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: Lang.get('messages.OK'),
                    cancelButtonText: Lang.get('messages.Cancel')
                }).then((response) => {
                    if (response.value) {
                        $(link).data('confirmed', true)
                        this.$nextTick(function () {
                            link.click()
                        })
                    }
                })
            }
        },

        finished: function() {
            clearTimeout(this.timerId);
            location.pathname = '/expired';
        },

        mobilePurchase: function (e) {

            //t&c's not accepted?
            if ($('#buyButton').hasClass('disabled')) {
                return
            }

            //get stripe session token
            axios.post('/client_token', {
                currency: window.location.href.substr(-3),
                item: this.selection,
            }).then((response) => {
                console.log('client token response', response)
                this.token = response.data.token
                let stripe = Stripe(response.data.key);
                return stripe.redirectToCheckout({ sessionId: this.token });
            }).then((result) => {
                if (result.error) {
                    alert(result.error.message);
                }
            }).catch((err) => {
                //this.purchaseCancel()
                console.log("Purchase failed", err);
            });

        },

        startTimer: function (e) {
            this.runTimer()
            $('#countdown').removeClass('d-none')
            this.timerId = setInterval(this.runTimer, 1000)
        },

        runTimer: function () {
            let expired = false;
            let seconds = Math.floor((this.eventAt.getTime() - new Date().getTime()) / 1000);
            if (seconds < 0) {
                seconds = Math.abs(++seconds);
                if (!this.countup) {
                    expired = true;
                    seconds = 0;
                }
            }
            const days = Math.floor(seconds / 86400);
            $('span.days-label').text(Lang.choice('time.days', days));

            seconds %= 86400;
            const hours = Math.floor(seconds / 3600);
            $('span.hours-label').text(Lang.choice('time.hours', hours));
            seconds %= 3600;
            const minutes = Math.floor(seconds / 60);
            $('span.minutes-label').text(Lang.choice('time.minutes', minutes));
            seconds %= 60;
            seconds = Math.floor(seconds);
            $('span.seconds-label').text(Lang.choice('time.seconds', seconds));

            $('span.days').text(days);
            $('span.hours').text(('00' + hours).slice(-2));
            $('span.minutes').text(('00' + minutes).slice(-2));
            $('span.seconds').text(('00' + seconds).slice(-2));

            if (expired) {
                return this.finished();
            }
        },

        fbPurchase: function (e) {
            let _this = this;
            if ($('#buyButton').hasClass('disabled')) {
                return
            }
            $(".fa-spinner").show().parent().addClass("disabled");
            // record request & get a requestId
            axios.post('/payment_request', {
                item: window.location.href + '/' + this.selection,
            }).then((response) => {
                _this.requestId = response.data
                let url =  _this.productUrl + _this.selection;
                //console.log('Request id=', _this.requestId, " Product url ", url);
                FB.ui({
                        method: 'pay',
                        action: 'purchaseitem',
                        product: url,
                        request_id: _this.requestId,
                    },
                    this.purchaseCallback,
                );
            });
        },

        purchaseCallback: function (result) {
            if (result && typeof result.error_code === 'undefined') {
                axios.post('/verify_payment', {
                    result: result,
                    requestId: this.requestId,
                }).then((response) => {
                    this.purchaseSuccess(response);
                }).catch((error) => {
                    this.purchaseError(error.message);
                });
            } else {
                this.purchaseCancel(result ? result.error_message: "unknown")
            }

        },

        purchaseCancel: function (msg) {
            if (typeof msg === 'undefined') {
                msg = Lang.get('messages.PurchaseCancelled')
            }
            this.purchaseError(msg)
            axios.post('/verify_payment', {
                cancel: this.requestId,
            });
            $(".fa-spinner").hide().parent().removeClass("disabled");

        },

        purchaseError: function (errorMsg) {
            Swal.fire({
                title: Lang.get('messages.PaymentStatus'),
                text: errorMsg,
                icon: 'error',
            });
            $(".fa-spinner").hide().parent().removeClass("disabled");
        },

        purchaseSuccess: function (response) {
            Swal.fire({
                title: Lang.get('messages.PaymentStatus'),
                text: response.data,
                icon: 'success',
            }).then(() => {
                location.reload();
            })
        },

        changeFont: function (e) {
            this.fontName = e.target.value
            if (this.fontName !== 'default') {
                $('#font').css('font-family', e.target.value)
            } else {
                $('#font').css('font-family', 'sans-serif')
            }
        },

        changeFontSize: function (e) {
            this.fontSize = e.target.value
        },

        getLang: function () {
            const lang = $('html').attr('lang').replace('_', '-');
            if (lang) {
                return lang
            }
            if (navigator.languages != undefined) {
                return navigator.languages[0]
            }
            return navigator.language ? navigator.language : 'en'
        },

        showFileDialog: function () {
            if (this.template.search(/^custom/) === 0) {
                $('#upload').collapse('show')
            } else {
                $('#upload').collapse('hide')
            }
        },

        selectTemplate: function (e) {
            if (!$(e.target).hasClass('disabled')) {
                const dataItem = $(e.target).parent();
                $('.tn-border').removeClass('tn-border')
                $(e.target).addClass('tn-border')
                this.template = $(e.target).data('template');
                this.custom = dataItem.data('custom');
                $('input[name=valign][value=' + dataItem.data('valign') + ']').parent().button('toggle');
                $('input[name=halign][value=' + dataItem.data('halign') + ']').parent().button('toggle');
                if (this.template.search(/^custom/) === -1) {
                    $('input[name=backgroundImage]').val('')
                } else {
                    $('input[name=backgroundImage]').val(this.hasImage)
                }
                this.color = dataItem.data('color')
                this.bgColor = dataItem.data('bgcolor')
                this.fontSize = dataItem.data('fontsize')
                this.showFileDialog()
            }
        },

    },
})
